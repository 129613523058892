<template>
    <v-row no-gutters>
        <v-col cols="12" sm="12" md="4">
            <label class="f-label">{{$t(label)}}</label>
        </v-col>
        <v-col cols="12" sm="12" md="8">
            <v-text-field
                @input="handle"
                v-model="content"
                hide-details
                required
                outlined
                dense 
                :rules="rules"               
            >
            </v-text-field>
        </v-col>
    </v-row>
</template>
<script>

export default {
    name: "FieldEditText",
    props: {
        label: {
            type: String,
            default: null
        },
        value: {
            type: String,
            default: null
        },
        rules: null
    },
    data() {
        return {
            content: this.value
        }
    },
    methods: {
        handle(e) {
            this.$emit('input', e);
        }
    }
};
</script>

